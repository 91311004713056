<!-- <template>
  <div class="customlesson layout-border" @click="numClick(numBers)">
    <div class="customlesson-content position-relative">
      <PageButton
        :bottomCornerButtonList="bottomCornerButtonList"
        :isLastStep="isLastStep"
        @clickNext="nextPage"
      ></PageButton>
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>

      <div class="customlesson-picture-switching" v-show="numBers === 1">
        <img
          src="@/assets/img/16-GAMES/G13-stage-2/background-dongdong.svg"
          alt=""
        />
      </div>
      <div class="customlesson-picture-switching" v-show="numBers === 2">
        <img src="@/assets/img/16-GAMES/G13-stage-2/background-bb.svg" alt="" />
      </div>
      <div class="customlesson-picture-switching" v-show="numBers === 3">
        <img
          src="@/assets/img/16-GAMES/G13-stage-2/background-godzilla.svg"
          alt=""
        />
      </div>
      <div class="customlesson-picture-switching" v-show="numBers === 4">
        <img
          src="@/assets/img/16-GAMES/G13-stage-2/background-kingkong.svg"
          alt=""
        />
      </div>
      <div class="customlesson-picture-switching" v-show="numBers === 5">
        <img
          src="@/assets/img/16-GAMES/G13-stage-2/background-panda.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";

import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";

export default {
  components: {
    PageButton,
    SceneSwitchThumbnails,
  },
  data() {
    return {
      numBers: 1,
      clickType: "",
      clickNum: 0,
      nextNum: false,
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G13-stage-2/menu-bb.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G13-stage-2/menu-godzilla.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G13-stage-2/menu-kingkong.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
        {
          image: require("@/assets/img/16-GAMES/G13-stage-2/menu-panda.svg"),
          startNumberRange: 5,
          endNumberRange: 5,
        },
      ],
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 1,
            endNumberRange: 5,
          },
        ],
      },
      clickSocketInfo: {},
      clickAsideSocketInfo: {},
    };
  },
  watch: {
    // 发送
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 30013,
          data: { value },
          text: "reviewGame按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickAsideSocketInfo: {
      handler(value) {
        const data = {
          clickType: 3001301,
          data: { value },
          text: "reviewGameAside按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("clickReviewGamePage", ({ type }) => {
      this.numClick(type, true);
    });
    this.$bus.$on("reviewGamePageAside", ({ index }) => {
      this.numClick(index - 1, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickReviewGamePage");
    this.$bus.$off("reviewGamePageAside");
  },
  methods: {
    changeTheNumbers(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickAsideSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.numBers = index;
      if (index < 5) {
        this.isLastStep = false;
      } else {
        this.isLastStep = true;
      }
    },
    numClick(type, isFromSocket) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          type,
          value: Math.random(),
        };
      }
      this.numBers = type + 1;
      playCorrectSound(true, false);

      if (this.numBers === 5) {
        this.isLastStep = true;
        startConfetti();
        playCorrectSound();
      }
      if (this.numBers > 5) {
        this.numBers = 5;
      }
    },
    updateNumbers(value) {
      this.numBers = value;
      if (this.numBers == 5) {
        this.isLastStep = true;
      }
      // if (this.numBers == 7) {
      //   this.$bus.$emit("nextButton", true);
      // }
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
  },
};
</script>
<style scoped lang="scss">
.customlesson {
  .customlesson-content {
    width: 100%;
    height: 100%;
    background-color: #f7f4e0;
    border-radius: 58px;
    position: relative;
    .customlesson-picture-switching {
      width: 100%;
      height: 100%;
      border-radius: 58px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-content: center;
      img {
        height: 100%;
      }
    }
  }
}
</style> -->
<template>
  <div class="lesson-container">
    <DialogPage
      :bgImg="bgImg"
      :pageInfoList="pageInfoList"
      :buttonList="buttonList"
      @clickTheBubble="clickTheBubble"
      :clickIdList="clickIdList"
      :lessonNo="33"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import DialogPage from "@/components/Course/CoursePage/DialogPage";
export default {
  data() {
    return {
      titleInfo: {
        pinyin: 'Duìbuqǐ. Méi guānxi.',
        hanzi: '-对不起。-没关系。'
      },
      clickIdList:[1,2,3,4,5,6],
      bgImg: require("@/assets/img/03-Backgrounds/level-1-chapter-5-background-blue.svg"),
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-33/menu-1.svg"),
          isCircle: true,
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-33/menu-3.svg"),
          isCircle: true,
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-33/menu-2.svg"),
          isCircle: true,
          startNumberRange: 3,
          endNumberRange: 3,
        },
      ],
      pageInfoList: [
        {
          index: 1,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-33/image-1.svg"),
          singlePageList: [
            {
              id: 1,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-33/bubble-1-left.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-33/bubble-question-left.svg"),
              isShowAnser: false,
            },
            {
              id: 2,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-33/bubble-1-right.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-33/bubble-question-right.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 2,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-33/image-2.svg"),
          singlePageList: [
            {
              id: 3,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-33/bubble-2-left.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-33/bubble-question-left.svg"),
              isShowAnser: false,
            },
            {
              id: 4,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-33/bubble-2-right.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-33/bubble-question-right.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 3,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-33/image-3.svg"),
          singlePageList: [
            {
              id: 5,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-33/bubble-3-left.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-33/bubble-question-left.svg"),
              isShowAnser: false,
            },
            {
              id: 6,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-33/bubble-3-right.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-33/bubble-question-right.svg"),
              isShowAnser: false,
            },
          ],
        },
        
      ],
      
    };
  },
  components: {
    DialogPage,
  },
  methods: {
    clickTheBubble(index, id) {
      this.pageInfoList[index - 1].singlePageList.forEach((item) => {
        if (item.id === id) {
          item.isShowAnser = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.lesson-container {
  width: 100%;
  height: 100%;
}
</style>