<template>
  <div class="game-container">
    <LearnReview
      :imgList="imgList"
      :wordsList="wordsList"
      :sentenceList="sentenceList"
      @deleteWords="handleDeleteWords"
    />
  </div>
</template>

<script>
import LearnReview from "@/components/Course/GamePage/LearnReviewGame";
export default {
  data() {
    return {
      imgList: [
        {
          id: 1,
          imgUrl: require("@/assets/img/11-Syllabus/lesson-02-heng-yellow.svg"),
          show: true,
          type: 3,
        },
        {
          id: 2,
          imgUrl: require("@/assets/img/11-Syllabus/lesson-02-shu-yellow.svg"),
          show: true,
          type: 3,
        },
        {
          id: 3,
          imgUrl: require("@/assets/img/11-Syllabus/lesson-02-ni-white.svg"),
          show: true,
          type: 3,
        },
      ],
      wordsList: [
        {
          id: 1,
          name: "yào",
          namePin: "要",
          show: true,
          type: 1,
        },
        {
          id: 2,
          name: "bāozi",
          namePin: "包子",
          show: true,
          type: 1,
        },
        {
          id: 3,
          name: "bēi",
          namePin: "杯",
          show: true,
          type: 1,
        },
        {
          id: 4,
          name: "duìbuqǐ",
          namePin: "对不起",
          show: true,
          type: 1,
        },
        {
          id: 5,
          name: "méi guānxi ",
          namePin: "没关系",
          show: true,
          type: 1,
        }
      ],
      sentenceList: [
        {
          id: 1,
          name: "Duìbuqǐ,māma",
          namePin: "对不起，妈妈。",
          show: true,
          type: 2,
        },
        {
          id: 2,
          name: "/Méi guānxi,Xiàxia.",
          namePin: "/没关系，夏夏。",
          show: true,
          type: 2,
        },
      ],
    };
  },
  components: {
    LearnReview,
  },
  methods: {
    handleDeleteWords({ type, id }) {
      if (type === 1) {
        this.wordsList[id - 1].show = false;
      } else if (type === 2) {
        this.sentenceList[id - 1].show = false;
      } else {
        this.imgList[id - 1].show = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>