<!-- <template>
  <div class="game-container">
    <StrokePath
      :firstAnimalInfo="firstAnimalInfo"
      :secondAnimalInfo="secondAnimalInfo"
      :puzzleBgImg="puzzleBgImg"
      :buttonList="buttonList"
      :puzzleItemList="puzzleItemList"
      :strokeStepsArr="strokeStepsArr"
      @avatarMove="handleMove"
      @getTarget="handleGetTarget"
      @firstStep="handleFirstStep"
      @initGame="handleInit"
      :lessonNum="lessonNum"
    />
  </div>
</template>

<script>
import StrokePath from "@/components/Course/GamePage/StrokePathGame";
export default {
  data() {
    return {
      lessonNum: 2,
      strokeStepsArr: [6, 8],
      puzzleItemList: [
        [
          { type: 0, isShow: false, index: 1 },
          { type: 1, isShow: false, index: 1 },
          { type: 0, isShow: false, index: null },
          { type: 1, isShow: false, index: null },
        ],
        [
          { type: 0, isShow: false, index: 2 },
          { type: 1, isShow: false, index: 2 },
          { type: 1, isShow: false, index: 3 },
          { type: 1, isShow: false, index: 4 },
        ],
        [
          { type: 0, isShow: false, index: 3 },
          { type: 1, isShow: false, index: null },
          { type: 0, isShow: false, index: null },
          { type: 1, isShow: false, index: 5 },
        ],
        [
          { type: 0, isShow: false, index: 4 },
          { type: 0, isShow: false, index: 5 },
          { type: 1, isShow: false, index: 7 },
          { type: 1, isShow: false, index: 6 },
        ],
        [
          { type: 1, isShow: false, index: null },
          { type: 0, isShow: false, index: 6 },
          { type: 1, isShow: false, index: 8 },
          { type: 0, isShow: false, index: null },
        ],
      ],
      // puzzleBgImg: require("@/assets/img/16-GAMES/G18-maze/lesson-02/puzzle.svg"),
      firstAnimalInfo: {
        // img: require("@/assets/img/16-GAMES/G18-maze/lesson-02/maze-bear.svg"),
        // finalImg: require("@/assets/img/16-GAMES/G18-maze/lesson-02/bear-honey.svg"),
        firstStrokeImgList: [
          // require("@/assets/img/16-GAMES/G18-maze/lesson-02/puzzle-shu-blue-1.svg"),
          // require("@/assets/img/16-GAMES/G18-maze/lesson-02/puzzle-shu-blue-2.svg"),
          // require("@/assets/img/16-GAMES/G18-maze/lesson-02/puzzle-shu-blue-3.svg"),
          // require("@/assets/img/16-GAMES/G18-maze/lesson-02/puzzle-shu-blue-4.svg"),
          // require("@/assets/img/16-GAMES/G18-maze/lesson-02/puzzle-shu-blue-5.svg"),
          // require("@/assets/img/16-GAMES/G18-maze/lesson-02/puzzle-shu-blue-6.svg"),
        ],
      },
      secondAnimalInfo: {
        // img: require("@/assets/img/16-GAMES/G18-maze/lesson-02/maze-dog.svg"),
        // finalImg: require("@/assets/img/16-GAMES/G18-maze/lesson-02/dog-bone.svg"),
        secondStrokeImgList: [
          // require("@/assets/img/16-GAMES/G18-maze/lesson-02/puzzle-heng-yellow-1.svg"),
          // require("@/assets/img/16-GAMES/G18-maze/lesson-02/puzzle-heng-yellow-2.svg"),
          // require("@/assets/img/16-GAMES/G18-maze/lesson-02/puzzle-heng-yellow-3.svg"),
          // require("@/assets/img/16-GAMES/G18-maze/lesson-02/puzzle-heng-yellow-4.svg"),
          // require("@/assets/img/16-GAMES/G18-maze/lesson-02/puzzle-heng-yellow-5.svg"),
          // require("@/assets/img/16-GAMES/G18-maze/lesson-02/puzzle-heng-yellow-6.svg"),
          // require("@/assets/img/16-GAMES/G18-maze/lesson-02/puzzle-heng-yellow-7.svg"),
          // require("@/assets/img/16-GAMES/G18-maze/lesson-02/puzzle-heng-yellow-8.svg"),
        ],
      },
      buttonList: [
        {
          // image: require("@/assets/img/16-GAMES/G18-maze/lesson-02/maze-bear.svg"),
          isPuzzlePage: true,
          startNumberRange: 1,
          endNumberRange: 3,
        },
        {
          // image: require("@/assets/img/16-GAMES/G18-maze/lesson-02/maze-dog.svg"),
          isPuzzlePage: true,
          startNumberRange: 2,
          endNumberRange: 3,
        },
      ],
    };
  },
  components: {
    StrokePath,
  },
  methods: {
    handleMove(indexObj) {
      const { lastRowIndex, lastColIndex, currentRowIndex, currentColIndex } =
        indexObj;
      console.log(lastRowIndex, lastColIndex, currentRowIndex, currentColIndex);
      this.puzzleItemList[lastRowIndex][lastColIndex].isShow =
        !this.puzzleItemList[lastRowIndex][lastColIndex].isShow;
      this.puzzleItemList[currentRowIndex][currentColIndex].isShow =
        !this.puzzleItemList[currentRowIndex][currentColIndex].isShow;
    },
    handleFirstStep(index) {
      if (!index) {
        this.puzzleItemList[0][0].isShow = true;
      } else {
        this.puzzleItemList[0][1].isShow = true;
      }
    },
    handleGetTarget(obj) {
      const { currentRowIndex: row, currentColIndex: col } = obj;
      console.log(row, col);
      this.puzzleItemList[row][col].isGetTarget = true;
    },
    handleInit() {
      this.puzzleItemList.forEach((item) => {
        item.forEach((ele) => {
          ele.isShow = false;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style> -->
<template>
  <div class="game-container">
    <FindStructureByPicGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      :totalStars="totalStars"
      :isNumberGame="false"
    />
  </div>
</template>

<script>
import FindStructureByPicGame from "@/components/Course/GamePage/FindStructureByPicGame";
export default {
  data() {
    return {
      totalStars: 5,
      questionInfoList: [
        {
          id: 2,
          bgImg: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-1-mei-1.svg"),
          bgImg2: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-1-mei-2.svg"),
          sound: require("@/assets/audio/a-zh/n/ni3.mp3"),
        },
        {
          id: 2,
          bgImg: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-2-mao-1.svg"),
          bgImg2: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-1-mei-2.svg"),
          sound: require("@/assets/audio/a-zh/w/wo3.mp3"),
        },
        {
          id: 2,
          bgImg: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-3-mei-1.svg"),
          bgImg2: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-2-mao-2.svg"),
          sound: require("@/assets/audio/a-zh/n/nihao.mp3"),
        },
        {
          id: 2,
          bgImg: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-4-ta-1.svg"),
          bgImg2: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-3-mei-2.svg"),
          sound: require("@/assets/audio/a-zh/L/laoshi.mp3"),
        },
        {
          id: 2,
          bgImg: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-5-na-1.svg"),
          bgImg2: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-4-ta-2.svg"),
          sound: require("@/assets/audio/a-zh/L/laoshi.mp3"),
        },
        {
          id: 2,
          bgImg: "",
          bgImg2: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-5-na-2.svg"),
          sound: require("@/assets/audio/a-zh/L/laoshi.mp3"),
        },
        
      ],
      asideElementInfo: {
        leftList: [
          {
            id: 1,
            image:require("@/assets/img/16-GAMES/G74-structure-puzzle/1-shangxia.svg"),
          },
          {
            id: 2,
            image:require("@/assets/img/16-GAMES/G74-structure-puzzle/2-zuoyou.svg"),
          },
          {
            id: 3,
            image:require("@/assets/img/16-GAMES/G74-structure-puzzle/3-quanbao.svg"),
          },
          {
            id: 4,
            image:require("@/assets/img/16-GAMES/G74-structure-puzzle/4-duti.svg"),
          },
         
        ],
        rightList: [
          
          {
            id: 5,
            image:require("@/assets/img/16-GAMES/G74-structure-puzzle/5-zuoshang.svg"),
          },
          {
            id: 6,
            image:require("@/assets/img/16-GAMES/G74-structure-puzzle/6-zuoxia.svg"),
          },
          {
            id: 7,
            image:require("@/assets/img/16-GAMES/G74-structure-puzzle/7-zuosan.svg"),
          },
         {
            id: 8,
            image:require("@/assets/img/16-GAMES/G74-structure-puzzle/8-xiasan.svg"),
          },
        ],
      },
    };
  },
  components: {
    FindStructureByPicGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
