<!-- <template>
  <div class="customlesson layout-border" @click="numClick(numBers)">
    <div class="customlesson-content position-relative">
      <PageButton
        :bottomCornerButtonList="bottomCornerButtonList"
        :isLastStep="isLastStep"
        @clickNext="nextPage"
      ></PageButton>
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>

      <div class="customlesson-picture-switching" v-show="numBers === 1">
        <img
          src="@/assets/img/16-GAMES/G13-stage-2/background-dongdong.svg"
          alt=""
        />
      </div>
      <div class="customlesson-picture-switching" v-show="numBers === 2">
        <img src="@/assets/img/16-GAMES/G13-stage-2/background-bb.svg" alt="" />
      </div>
      <div class="customlesson-picture-switching" v-show="numBers === 3">
        <img
          src="@/assets/img/16-GAMES/G13-stage-2/background-godzilla.svg"
          alt=""
        />
      </div>
      <div class="customlesson-picture-switching" v-show="numBers === 4">
        <img
          src="@/assets/img/16-GAMES/G13-stage-2/background-kingkong.svg"
          alt=""
        />
      </div>
      <div class="customlesson-picture-switching" v-show="numBers === 5">
        <img
          src="@/assets/img/16-GAMES/G13-stage-2/background-panda.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";

import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";

export default {
  components: {
    PageButton,
    SceneSwitchThumbnails,
  },
  data() {
    return {
      numBers: 1,
      clickType: "",
      clickNum: 0,
      nextNum: false,
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G13-stage-2/menu-bb.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G13-stage-2/menu-godzilla.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G13-stage-2/menu-kingkong.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
        {
          image: require("@/assets/img/16-GAMES/G13-stage-2/menu-panda.svg"),
          startNumberRange: 5,
          endNumberRange: 5,
        },
      ],
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 1,
            endNumberRange: 5,
          },
        ],
      },
      clickSocketInfo: {},
      clickAsideSocketInfo: {},
    };
  },
  watch: {
    // 发送
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 30013,
          data: { value },
          text: "reviewGame按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickAsideSocketInfo: {
      handler(value) {
        const data = {
          clickType: 3001301,
          data: { value },
          text: "reviewGameAside按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("clickReviewGamePage", ({ type }) => {
      this.numClick(type, true);
    });
    this.$bus.$on("reviewGamePageAside", ({ index }) => {
      this.numClick(index - 1, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickReviewGamePage");
    this.$bus.$off("reviewGamePageAside");
  },
  methods: {
    changeTheNumbers(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickAsideSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.numBers = index;
      if (index < 5) {
        this.isLastStep = false;
      } else {
        this.isLastStep = true;
      }
    },
    numClick(type, isFromSocket) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          type,
          value: Math.random(),
        };
      }
      this.numBers = type + 1;
      playCorrectSound(true, false);

      if (this.numBers === 5) {
        this.isLastStep = true;
        startConfetti();
        playCorrectSound();
      }
      if (this.numBers > 5) {
        this.numBers = 5;
      }
    },
    updateNumbers(value) {
      this.numBers = value;
      if (this.numBers == 5) {
        this.isLastStep = true;
      }
      // if (this.numBers == 7) {
      //   this.$bus.$emit("nextButton", true);
      // }
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
  },
};
</script>
<style scoped lang="scss">
.customlesson {
  .customlesson-content {
    width: 100%;
    height: 100%;
    background-color: #f7f4e0;
    border-radius: 58px;
    position: relative;
    .customlesson-picture-switching {
      width: 100%;
      height: 100%;
      border-radius: 58px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-content: center;
      img {
        height: 100%;
      }
    }
  }
}
</style> -->
<template>
  <div class="game-container">
    <PhoneGame
      :answerList="answerList"
    />
  </div>
</template>

<script>
import PhoneGame from "@/components/Course/GamePage/PhoneGame";
export default {
  data() {
    return {
      answerList: [
        {
          answerInfo:"三杯水",
          answerPin:"sān bēi shuǐ ",
          audioSrc: require("@/assets/audio/a-zh/a/a1.mp3"),
          rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
          allImgList: [
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-done.svg`),
              id:1,
              pinyin:"kě",
              hanzi:"可",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-done.svg`),
              id:2,
              pinyin:"bù",
              hanzi:"不",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-done.svg`),
              id:3,
              pinyin:"yǐ",
              hanzi:"以",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-done.svg`),
              id:4,
              pinyin:"qǐng",
              hanzi:"请",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-done.svg`),
              id:5,
              pinyin:"kè",
              hanzi:"客",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-done.svg`),
              id:6,
              pinyin:"qi",
              hanzi:"气",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-done.svg`),
              id:7,
              pinyin:"zuò",
              hanzi:"坐",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
            
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
            },
          ],
        },
        {
          answerInfo:"五个包子",
          answerPin:"wǔ ge bāozi ",
          audioSrc: require("@/assets/audio/a-zh/a/a1.mp3"),
          rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/keyi.svg`),
          allImgList: [
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-done.svg`),
              id:1,
              pinyin:"kě",
              hanzi:"可",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-done.svg`),
              id:2,
              pinyin:"bù",
              hanzi:"不",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-done.svg`),
              id:3,
              pinyin:"yǐ",
              hanzi:"以",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-done.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-done.svg`),
              id:4,
              pinyin:"qǐng",
              hanzi:"请",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-done.svg`),
              id:5,
              pinyin:"kè",
              hanzi:"客",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-done.svg`),
              id:6,
              pinyin:"qi",
              hanzi:"气",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-done.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-done.svg`),
              id:7,
              pinyin:"zuò",
              hanzi:"坐",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
            
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
            },
          ],
        },
        {
          answerInfo:"对不起",
          answerPin:"duìbuqǐ",
          audioSrc: require("@/assets/audio/a-zh/a/a1.mp3"),
          rightImg:require(`@/assets/img/12-Vocabulary/L2/lesson-03-duibuqi.svg`),
          allImgList: [
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-done.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-done.svg`),
              id:1,
              pinyin:"kě",
              hanzi:"可",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-done.svg`),
              id:2,
              pinyin:"bù",
              hanzi:"不",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-done.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-done.svg`),
              id:3,
              pinyin:"yǐ",
              hanzi:"以",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-done.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-yellow.svg`),
              id:4,
              pinyin:"qǐng",
              hanzi:"请",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-done.svg`),
              id:5,
              pinyin:"kè",
              hanzi:"客",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-done.svg`),
              id:6,
              pinyin:"qi",
              hanzi:"气",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-done.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-yellow.svg`),
              id:7,
              pinyin:"zuò",
              hanzi:"坐",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
            
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
            },
          ],
        },
      ],
    };
  },
 
  components: {
    PhoneGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>