<!-- <template>
  <div class="customlesson layout-border" @click="numClick(numBers)">
    <div class="customlesson-content position-relative">
      <PageButton
        :bottomCornerButtonList="bottomCornerButtonList"
        :isLastStep="isLastStep"
        @clickNext="nextPage"
      ></PageButton>
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>

      <div class="customlesson-picture-switching" v-show="numBers === 1">
        <img
          src="@/assets/img/16-GAMES/G13-stage-2/background-dongdong.svg"
          alt=""
        />
      </div>
      <div class="customlesson-picture-switching" v-show="numBers === 2">
        <img src="@/assets/img/16-GAMES/G13-stage-2/background-bb.svg" alt="" />
      </div>
      <div class="customlesson-picture-switching" v-show="numBers === 3">
        <img
          src="@/assets/img/16-GAMES/G13-stage-2/background-godzilla.svg"
          alt=""
        />
      </div>
      <div class="customlesson-picture-switching" v-show="numBers === 4">
        <img
          src="@/assets/img/16-GAMES/G13-stage-2/background-kingkong.svg"
          alt=""
        />
      </div>
      <div class="customlesson-picture-switching" v-show="numBers === 5">
        <img
          src="@/assets/img/16-GAMES/G13-stage-2/background-panda.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";

import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";

export default {
  components: {
    PageButton,
    SceneSwitchThumbnails,
  },
  data() {
    return {
      numBers: 1,
      clickType: "",
      clickNum: 0,
      nextNum: false,
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G13-stage-2/menu-bb.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G13-stage-2/menu-godzilla.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G13-stage-2/menu-kingkong.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
        {
          image: require("@/assets/img/16-GAMES/G13-stage-2/menu-panda.svg"),
          startNumberRange: 5,
          endNumberRange: 5,
        },
      ],
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 1,
            endNumberRange: 5,
          },
        ],
      },
      clickSocketInfo: {},
      clickAsideSocketInfo: {},
    };
  },
  watch: {
    // 发送
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 30013,
          data: { value },
          text: "reviewGame按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickAsideSocketInfo: {
      handler(value) {
        const data = {
          clickType: 3001301,
          data: { value },
          text: "reviewGameAside按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("clickReviewGamePage", ({ type }) => {
      this.numClick(type, true);
    });
    this.$bus.$on("reviewGamePageAside", ({ index }) => {
      this.numClick(index - 1, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickReviewGamePage");
    this.$bus.$off("reviewGamePageAside");
  },
  methods: {
    changeTheNumbers(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickAsideSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.numBers = index;
      if (index < 5) {
        this.isLastStep = false;
      } else {
        this.isLastStep = true;
      }
    },
    numClick(type, isFromSocket) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          type,
          value: Math.random(),
        };
      }
      this.numBers = type + 1;
      playCorrectSound(true, false);

      if (this.numBers === 5) {
        this.isLastStep = true;
        startConfetti();
        playCorrectSound();
      }
      if (this.numBers > 5) {
        this.numBers = 5;
      }
    },
    updateNumbers(value) {
      this.numBers = value;
      if (this.numBers == 5) {
        this.isLastStep = true;
      }
      // if (this.numBers == 7) {
      //   this.$bus.$emit("nextButton", true);
      // }
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
  },
};
</script>
<style scoped lang="scss">
.customlesson {
  .customlesson-content {
    width: 100%;
    height: 100%;
    background-color: #f7f4e0;
    border-radius: 58px;
    position: relative;
    .customlesson-picture-switching {
      width: 100%;
      height: 100%;
      border-radius: 58px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-content: center;
      img {
        height: 100%;
      }
    }
  }
}
</style> -->
<template>
  <div class="game-container">
    <ChooseImgDog
      :allImgList="allImgList"
      :buttonList="buttonList"
      :answerList="answerList"
      :mailboxList="mailboxList"
      :dragNum="dragNum"
      :durationArr="durationArr"
    />
  </div>
</template>

<script>
import ChooseImgDog from "@/components/Course/GamePage/ChooseImgDog";
export default {
  data() {
    return {
      dragNum: 4,
      answerList: [1,2,3,4],
      durationArr: [0, 0.5, 0.7, 0.9, 1],
      allImgList: [
        {
          imgList:[
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
              rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
              isStartPlaying:null,
              pinYin: "yì bēi",
              hanZi: "一杯 ",
              audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
              id:3,
              color:'#fff',
              isDispear: false,
              key: 3,
              index: 3,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
              rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
              isStartPlaying:null,
              pinYin: "zhèr",
              hanZi: "这儿 ",
              audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
              id:1,
              color:'#fff',
              isDispear: false,
              key: 1,
              index: 1,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
              rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
              isStartPlaying:null,
              pinYin: "yǒu",
              hanZi: "有 ",
              audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
              id:2,
              color:'#fff',
              isDispear: false,
              key: 2,
              index: 2,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
              rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
              isStartPlaying:null,
              pinYin: "niúnǎi.",
              hanZi: "牛奶。",
              audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
              id:4,
              color:'#fff',
              isDispear: false,
              key: 4,
              index: 4,
            },
          ]
        },
        {
          imgList:[
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
              rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
              isStartPlaying:null,
              pinYin: "chī",
              hanZi: "吃 ",
              audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
              id:3,
              color:'#fff',
              isDispear: false,
              key: 3,
              index: 3,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
              rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
              isStartPlaying:null,
              pinYin: " wǒ",
              hanZi: "我 ",
              audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
              id:1,
              color:'#fff',
              isDispear: false,
              key: 1,
              index: 1,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
              rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
              isStartPlaying:null,
              pinYin: "yào",
              hanZi: "要 ",
              audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
              id:2,
              color:'#fff',
              isDispear: false,
              key: 2,
              index: 2,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
              rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
              isStartPlaying:null,
              pinYin: "bāozi.",
              hanZi: "包子。",
              audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
              id:4,
              color:'#fff',
              isDispear: false,
              key: 4,
              index: 4,
            }
          ]
        }
      ],
      mailboxList:[
        {
          value: 1,
        },
        {
          value: 2,
        },
        {
          value: 3,
        },
        {
          value: 4,
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/menu-1.svg"),
          rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
          isStartPlaying:null,
          audioSrc: require("@/assets/audio/chapter_1/ni-hao.mp3"),
          isCircle: true,
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/menu-1.svg"),
          rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
          isStartPlaying:null,
          audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
          isCircle: true,
          startNumberRange: 2,
          endNumberRange: 2,
        },
      ],
    };
  },
 
  components: {
    ChooseImgDog,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>