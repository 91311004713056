<!-- <template>
  <div class="recognition-page-heng-container">
    <RecognitionStrokePage
      :backgroundImage="bgImg"
      :strokeObj="strokeDetail"
    />
  </div>
</template>

<script>
import RecognitionStrokePage from "@/components/Course/CoursePage/RecognitionStrokePage";

export default {
  components: {
    RecognitionStrokePage,
  },
  data() {
    return {
      bgImg: require("@/assets/img/15-Hanzi-strokes/lesson-02-heng-image.svg"),
      strokeDetail: {
        pinName: "héng",
        chineseName: "横",
        strokeImg: require("@/assets/img/15-Hanzi-strokes/lesson-02-heng-stroke.svg"),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-heng-container {
  width: 100%;
  height: 100%;
  position: relative;
}
</style> -->
<!-- <template>
  <div class="game-container">
    <ChooseStructureGame :optionList="optionList" :imgList="imgList" :titleInfo="titleInfo" />
  </div>
</template>

<script>
import ChooseStructureGame from "@/components/Course/GamePage/ChooseStructureGame";
export default {
  data() {
    return {
      titleInfo:{
        pinyin:"zuǒyòu jiégòu",
        hanzi:"左右结构"
      },
      optionList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-ni.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-hao.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-ma.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-gou.svg"),
        },
       {
          id: 5,
          image: require("@/assets/img/16-GAMES/G75-structure-choose-5/l2-lesson-02/hanzi-hen.svg"),
        },
      ],

      imgList: [
        [{
          id: 1,
          pinying: "nǐ",
          hanzi: "你",
        },
        {
          pinying: "shì",
          hanzi: "是",
        },
        {
          pinying: "lǎo",
          hanzi: "老",
        },
        ],
        [{
          pinying: "ài",
          hanzi: "爱",
        },
        {
          pinying: "bà",
          hanzi: "爸",
        },
        {
          id: 2,
          pinying: "hǎo",
          hanzi: "好",
        },
        ],
        [{
          pinying: "kàn",
          hanzi: "看",
        },
          {
          id: 3,
          pinying: "mā",
          hanzi: "妈",
        },
        {
          pinying: "guo",
          hanzi: "国",
        },
        ],
        [{
          pinying: "ài",
          hanzi: "耳",
        },
        {
          id: 4,
          pinying: "gou",
          hanzi: "狗",
        },
        {
          pinying: "niǎo",
          hanzi: "鸟",
        },
        ],
        [{
          id: 5,
          pinying: "hen",
          hanzi: "很",
        },
        {
          pinying: "tou",
          hanzi: "头",
        },
        {
          pinying: "miàn",
          hanzi: "面",
        },
        ],
      ],
    };
  },
  components: {
    ChooseStructureGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
 -->
 <template>
  <div class="recognition-page-Two">
    <RecognitionImgPage :backgroundImage="backgroundImage" :wordObj="wordObj" :rightImg="rightImg" :changeBgImg="changeBgImg"/>
  </div>
</template>

<script>
import RecognitionImgPage from "@/components/Course/CoursePage/RecognitionImgPage";
export default {
  data() {
    return {
      backgroundImage: require("@/assets/img/16-GAMES/G68-structure-card/lesson-02-zuoyou-1.svg"),
      changeBgImg: require("@/assets/img/16-GAMES/G68-structure-card/lesson-02-zuoyou-2.svg"),
      rightImg:require("@/assets/img/16-GAMES/G68-structure-card/lesson-02-zuoyou.svg"),
      wordObj: {
        pinyin: "zuǒyòu jiégòu",
        hanzi: "左右结构",
        songUrl: require("@/assets/audio/a-zh/h/he2.mp3"),

      },
    };
  },
  components: {
    RecognitionImgPage,
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-Two {
  width: 100%;
  height: 100%;
}
</style>